<template>
  <div>
    <ContinueModalBasket :pre_session_counseling="pre_session_counseling"/>
    <div class="factorArea" v-if="factor && profile">
      <div class="titlePage text-center mt-10">
        {{ $t('singleFactor.factor') }}
      </div>
      <div class="mt-10">
        <v-row>
          <v-col cols="12" lg="8">
            <div class="basketItems">
              <div class="titleBasket">
                {{ $t('singleFactor.title') }}
              </div>
              <div class="divider"></div>
              <v-data-table
                  v-if="factor"
                  :headers="headers"
                  disable-pagination
                  hide-default-footer
                  :items="factor.packages"
              >
                <template v-slot:item.title="{item}">
                  <p style="font-size: 12px" class="mt-3">
                    <template v-if="$vuetify.rtl">
                      {{ item.title }}
                    </template>
                    <template v-else>
                      {{ item.en_title }}
                    </template>
                  </p>
                </template>
                <template v-slot:item.qty="{item}">
                  <p style="font-size: 12px" class="mt-3">
                    {{ item.pivot.quantity }}
                  </p>
                </template>
                <template v-slot:item.total_amount="{item}">
                  <p style="font-size: 12px" class="mt-3">
                    {{ withCurrency(item.pivot.final_price, factor.currency, 'long') }}
                  </p>
                </template>
                <template v-slot:item.discount="{item}">
                  <p style="font-size: 12px" class="mt-3">
                    {{ withCurrency(item.pivot.discount, factor.currency, 'long') }}
                  </p>
                </template>
              </v-data-table>
              <div class="titleBasket mt-10">
                {{ $t('singleFactor.payments') }}
              </div>
              <div class="divider"></div>
              <v-data-table
                  v-if="factor"
                  :headers="paymentHeader"
                  :items="factor.payments"
                  hide-default-footer
                  disable-pagination
              >
                <template v-slot:item.date="{item}">
                  <p style="font-size: 12px" class="mt-5">
                    {{ item.date | dataSettingFilter }}
                    <br>
                    {{ dataSettingFilterJalali(factor.submitted_at) }}
                  </p>
                </template>
                <template v-slot:item.type="{item}">
                  <p style="font-size: 12px" class="mt-5">
                    {{ factorPaymentType(item) }}
                  </p>
                </template>
                <template v-slot:item.state="{item}">
                  <p style="font-size: 12px" class="mt-5">
                    {{ factorPaymentState(item) }}
                  </p>
                </template>
                <template v-slot:item.amount="{item}">
                  <p style="font-size: 12px" class="mt-5">
                    {{ withCurrency(item.final_amount, item.currency, 'long') }}
                  </p>
                </template>
              </v-data-table>

            </div>
          </v-col>
          <v-col cols="12" lg="4">
            <div class="FactorOverView">
              <div class="titleBasket text-center">
                {{ $t('singleFactor.factor') }}
              </div>
              <div class="divider" style="margin: 20px auto"></div>
              <div class="factorData mt-10">
                <div class="factorItem">
                  <div class="itemTitle">
                    {{ $t('basket.factor') }}
                  </div>
                  <div class="itemData">
                    {{ withCurrency(factor.price, factor.currency, 'long') }}
                  </div>
                </div>
                <div class="factorItem mt-5">
                  <div class="itemTitle">
                    {{ $t('Paid') }}
                  </div>
                  <div class="itemData">
                    {{ withCurrency(factor.paid_amount, factor.currency, 'long') }}
                  </div>
                </div>
                <!--                <div class="factorItem mt-5" v-if="wallet.visibility">-->
                <!--                  <div class="itemTitle">-->
                <!--                    <div class="d-flex">-->
                <!--                      <div>-->
                <!--                        {{ $t('singleFactor.wallet') }}-->
                <!--                      </div>-->
                <!--                      <div>-->
                <!--                        <v-switch-->
                <!--                            inset-->
                <!--                            v-model="wallet.status"-->
                <!--                            :readonly="wallet.readonly"-->
                <!--                            color="#46b1a1"-->
                <!--                            dense-->
                <!--                            style="display: inline-block;margin-top: -5px"-->
                <!--                            class="mx-1"-->
                <!--                        ></v-switch>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                  <div class="itemData">-->
                <!--                    {{ withCurrency(profile.affiliate_wallet[factor.currency].balance, factor.currency, 'long') }}-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <hr class="mt-3" style="background: #b9b9b9">
              <div class="finalAmount">
                <div class="titleFinalAmount">
                  {{ $t('singleFactor.remaining') }}
                </div>
                <div class="dataFinalAmount">
                  {{
                    withCurrency(finalPrice(factor.remaining_amount, profile.affiliate_wallet[factor.currency].balance), factor.currency, 'long')
                  }}
                </div>
              </div>
              <div id="payBtnArea" class="mt-8" v-if="factor.canBePaid">
                <div class="text-center mt-15 d-flex justify-space-between">
                  <div>
                    <img src="../../assets/Shopify_logo_2018.svg.png" width="50px" alt="">
                  </div>
                  <div>
                    <img src="../../assets/apple_pay.png" width="50px" alt="">
                  </div>
                  <div>
                    <img src="../../assets/G-Pay.jpg" width="50px" alt="">
                  </div>
                  <div>
                    <img src="../../assets/master_card.webp" width="25px" alt="">
                  </div>
                  <div>
                    <img src="../../assets/visa.jpg" width="50px" alt="">
                  </div>
                </div>

                <v-btn class="payBtn" :id="payBtnId" color="#46b1a1" elevation="0" block
                       @click="payfactor(cart,wallet)">
                  {{ $t('basket.onlinePay') }}
                </v-btn>
                <v-btn class="offlinePayBtn" outlined :disabled="isPayDisable" :id="payBtnId" color="#46b1a1"
                       elevation="0" block
                       @click="showOfflinePaymentDialog = true">
                  {{ $t('basket.offlinePay') }}
                </v-btn>
                <v-dialog v-model="showOfflinePaymentDialog" max-width="800" scrollable>
                  <ClientOfflinePayment v-if="showOfflinePaymentDialog"
                                        @done="savingOfflineDone"
                                        :preorder="false"
                                        :cart="factor"
                                        type="factors"
                                        :show="showOfflinePaymentDialog"
                                        @close="showOfflinePaymentDialog=false"
                  ></ClientOfflinePayment>
                </v-dialog>
                <!--                <v-btn class="payOfflineBtn mt-3" :id="payBtnId" color="#46b1a1" outlined block>-->
                <!--                  {{ $t('basket.offlinePay') }}-->
                <!--                </v-btn>-->
                <v-bottom-navigation class="elevation-0" color="red" fixed
                                     v-if="$vuetify.breakpoint.mdAndDown && $route.name == 'preorder'">
                  <v-btn style="color:white!important;font-size: 15px" :id="payBtnId" color="#46b1a1" elevation="0"
                         block
                         class="payBtnBottomNavigation"
                         @click="saveorder">
                    {{ $t('basket.onlinePay') }}
                  </v-btn>
                </v-bottom-navigation>

              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ContinueModalBasket from "@/components/Basket/ContinueModalBasket";
import EventsMixins from "@/mixins/EventsMixins";
import CartMixin from "@/mixins/CartMixin";
import DateMixins from "@/mixins/DateMixins";
import ClientOfflinePayment from "@/components/Basket/ClientOfflinePayment";

export default {
  mixins: [EventsMixins, CartMixin, DateMixins],
  components: {ClientOfflinePayment, ContinueModalBasket},
  data() {
    return {
      showOfflinePaymentDialog: false,
      headers: [
        {text: this.$t('singleFactor.datatable.title'), value: 'title', sortable: false, align: 'start'},
        {text: this.$t('singleFactor.datatable.qty'), value: 'qty', sortable: false, align: 'center'},
        {text: this.$t('singleFactor.datatable.off'), value: 'discount', sortable: false, align: 'center'},
        {text: this.$t('singleFactor.datatable.totalAmount'), value: 'total_amount', sortable: false, align: 'center'},
      ],
      factor: null,
      paymentHeader: [
        {
          text: this.$t('factors.datatable.date'),
          sortable: false,
          value: 'date',
        },
        {text: this.$t('factors.datatable.type'), sortable: false, value: 'type'},
        {text: this.$t('factors.datatable.state'), sortable: false, value: 'state'},
        {text: this.$t('factors.datatable.amount'), sortable: false, value: 'amount'},
      ],
      pre_session_counseling: {
        visibility: false,
      },
    }
  },
  methods: {
    savingOfflineDone() {
      this.showOfflinePaymentDialog = false;
      // let message = this.$t('Notify.successfullyWithRedirect');
      this.getFactor()
      // this.$emitEvent('notify', {title: message, color: 'green', id: 'ga_offline_payment',});
    },
    payfactor() {
      this.requesting('factor', 'payFactor', {
        factorID: this.factor.id
      }).then((resp) => {
        if (resp.data.state == 'SUCCESS') {
          window.location = resp.data.url
        }
      })
    },
    getFactor() {
      this.$emitEvent('setLoading', true)
      this.requesting('factor', 'getFactor', {
        factorID: this.$route.params.factorID
      }).then((resp) => {
        this.factor = resp.data.factor
        // this.prepareWalletUsed(this.factor.currency)
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
  },
  mounted() {
    this.getFactor()
  },
  watch: {
    // 'wallet.status'(val) {
    //   if (val) {
    //     this.requesting('factor', 'addWalletRecord', {
    //       factorID: this.factor.id
    //     })
    //   }
    // }
  },
  computed: {
    ...mapGetters({
      currency: 'getUserCurrency',
      profile: 'getProfile'
    }),
  }
}
</script>

<style scoped>
.factorArea {
  min-height: 80%;
  /*max-height: auto;*/
  height: auto;
  width: 99%;
  margin: 0px auto;
  border-radius: 18px;
  padding: 20px;

}

.basketItems {
  border-radius: 10px;
  border: solid 1px rgb(219, 219, 219);
  background-color: rgb(255, 255, 255);
  padding: 40px 20px;
  height: 100%;
}

.titleBasket {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0, 0, 0);
}

.titlePage {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0, 0, 0);
}

.divider {
  width: 100px;
  height: 2px;
  background: #000000;
  margin: 20px 0px 10px 0px;
}

.qtyArea {
  border: solid 1px rgb(219, 219, 219);
  padding-top: 3px;
  border-radius: 10px;
  text-align: center;
  width: 55px;
  height: 25px;
  font-size: 12px;
  cursor: pointer;
}

.FactorOverView {
  border-radius: 10px;
  border: solid 1px rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  padding: 40px 20px;
}

.factorItem {
  display: flex;
  justify-content: space-between;
  /*margin-bottom: 20px;*/
}

.itemTitle {
  font-size: 12px;
  color: #6d6d6d;
}

.itemData {
  font-size: 12px;
  color: #6d6d6d;
}

.finalAmount {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.titleFinalAmount {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: right;
  color: rgb(0, 0, 0);
}

.dataFinalAmount {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: right;
  color: rgb(0, 0, 0);
}

.titleBasket {
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: rgb(0, 0, 0);
}

.payBtn {
  height: 50px !important;
  border-radius: 10px !important;
  font-size: 14px;
  color: white !important;
  margin-top: 10px;
}

.payOfflineBtn {
  height: 50px !important;
  border-radius: 10px !important;
  font-size: 14px;
}

.offlinePayBtn {
  height: 50px !important;
  border-radius: 10px !important;
  font-size: 14px;
  margin-top: 10px;
}

</style>