import moment from "moment-jalaali";
/*eslint-disable*/
export default {
    methods: {
        filteringTime(date, format = "Y/M/D") {
            // return date
            return moment(date).format(format)
        },
        filteringTimeTexted(val) {
            let month = moment(val).format('MMMM')
            let date = moment(val).format('DD/YYYY')
            return month.substr(0, 3) + "/" + date
        },
        dataSettingFilterJalali(date) {
            if (date) {
                let month = moment(date).format('jMMMM')
                let day = moment(date).format('jDD')
                let year = moment(date).format('jYYYY')
                // return day + '-' + this.$t(`months.${month}`) + '/' + year
                // return year + " / "  + day + this.$t(`months.${month}`)
                return moment(date).format('jYYYY/jMM/jDD')
                // return year + '/' + this.$t(`months.${month}`) + '/' + day
            } else {
                return ""
            }
        },
    },
    filters: {
        dataSettingFilter(date) {
            if (date) {
                let month = moment(date).format('MMMM').substr(0, 3)
                let day = moment(date).format('DD')
                let year = moment(date).format('Y')
                return moment(date).format('YYYY/MM/DD')

                // return day + '/' + month + '/' + year
            } else {
                return ""
            }
        },

    }
}